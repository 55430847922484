//Libraries
import React, { FunctionComponent } from 'react'
import Img from 'gatsby-image'

//Components
import TeamPicturesSection from '../free/teamPicturesSection'

//Types
import { ADRCSectionTypes } from '../z_components-types'

//Styles
import styles from './css/asdrSection.module.scss'

const ASDRSection: FunctionComponent<ADRCSectionTypes> = ({ data }) => {
  return (
    <div className={styles.section}>
      <div className={styles.reverse}>
        <h2 className={styles.name}>{data.frontmatter.name}</h2>
        <Img
          fluid={data.frontmatter.img.childImageSharp.fluid}
          alt="Support group image"
          className={styles.image}
        />
      </div>
      <span dangerouslySetInnerHTML={{ __html: data.html }}></span>

      <TeamPicturesSection team_pictures={data.frontmatter.team_pictures} />
    </div>
  )
}

export default ASDRSection
