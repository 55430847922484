//Libraries
import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//Context
import { FontSizeProvider } from '../context/fontSizeContext'

//Types
import { PageWithLinksAndSectionTypes, SectionType } from './z_page-types'

//Components
import Header from '../components/header/header'
import Layout from '../components/layout/layout'
import Footer from '../components/footer/footer'
import ADRCSection from '../components/templates/asdrSection'
import PageWithLinks from '../components/templates/pageWithLinks'

const ADRCPrograms: FunctionComponent<PageWithLinksAndSectionTypes> = ({
  data,
}) => {
  const { allMarkdownRemark } = data
  const { edges } = allMarkdownRemark

  const isLinks: boolean =
    data.markdownRemark.frontmatter.links.length !== 0 ? true : false

  return (
    <FontSizeProvider>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet="utf-8" />
        <title>OOA - ADRC Programs</title>
        <link rel="canonical" href="https://ooa.csi.edu/adrc-programs/" />
        <meta
          name="description"
          content="The Aging and Disability Resource Center (ADRC) serves as a single point of entry into the long-term supports and services system for older adults and people with disabilities."
        />
      </Helmet>

      <Header />

      <Layout>
        <PageWithLinks
          html={data.markdownRemark.html}
          frontmatter={data.markdownRemark.frontmatter}
        >
          {data.markdownRemark.html && <hr />}

          {edges
            .sort((a, b) =>
              a.node.frontmatter.name < b.node.frontmatter.name ? -1 : 1
            )
            .map((program: SectionType, index: number) => (
              <div key={program.node.frontmatter.name}>
                <ADRCSection data={program.node} />
                {(isLinks || index + 1 !== edges.length) && <hr />}
              </div>
            ))}
        </PageWithLinks>
      </Layout>

      <Footer />
    </FontSizeProvider>
  )
}

export default ADRCPrograms

export const adrcProgramsQuery = graphql`
  query adrcPrograms {
    markdownRemark(
      frontmatter: { template_key: { eq: "adrc-programs-page" } }
    ) {
      html
      frontmatter {
        title
        links {
          link_name
          link
        }
        img {
          childImageSharp {
            fluid(maxWidth: 1700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { template_key: { eq: "adrc-program" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            name
            img {
              childImageSharp {
                fluid(maxWidth: 1700, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            team_pictures {
              name
              title
              img {
                childImageSharp {
                  fluid(maxWidth: 1700, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
